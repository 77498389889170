import { Grid, Typography } from '@mui/material';
import { MenuItem as MenuItemType } from '../../../../common/context/models';
import { getCdnImageUrl } from '../../../../common/helpers/image';
import { useStyles } from './Theme1.styles';
import { currencyFormat } from '../../../../common/utils/helper';
import { useResponsive } from '../../../../common/hooks/useResponsive';
import { Logo } from '../../../../common/components/Logo/Logo';
import { useAppSelector } from '../../../../common/context/hooks';

interface MenuProps {
  data: MenuItemType;
  classes: any;
  reverse?: boolean;
}

export const Theme1 = ({ menu }: { menu: MenuItemType[] }) => {
  const { classes } = useStyles();
  const brand = useAppSelector((state) => state.general.brand);

  return (
    <Grid xs={12} item container>
      <header className={classes.header}>
        <img src="/menu-header.jpg" alt="MENÜ" className={classes.imageBg} />
        <Typography className={classes.title}>
          {brand?.store?.logo ? (
            <Logo xs={12} md={12} lg={12} justifyContent="center" />
          ) : (
            brand?.store.title
          )}
        </Typography>
      </header>
      <Grid
        style={{ paddingTop: 20, paddingBottom: 200 }}
        justifyContent="center"
        container
      >
        <Grid rowSpacing={5} container xs={12} lg={8} justifyContent="center">
          {menu?.map((item, index) => (
            <MenuItem
              key={item.id}
              data={item}
              classes={classes}
              reverse={index % 2 === 1}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const MenuItem = ({ data, classes, reverse }: MenuProps) => {
  const { isMd } = useResponsive();
  const imageXs = isMd.up ? (data?.image ? 4 : 0) : 12;
  const productXs = isMd.up ? (data?.image ? 8 : 0) : 12;

  return (
    <Grid
      xs={12}
      className={classes.menuItem}
      justifyContent="space-between"
      direction={reverse ? 'row' : 'row-reverse'}
      spacing={2}
      item
      container
    >
      <Grid xs={imageXs} alignItems="center" item container>
        {data?.image && (
          <img
            src={getCdnImageUrl(data?.image)}
            width="100%"
            alt={data?.name}
          />
        )}
      </Grid>
      <Grid xs={productXs} lg={data?.image ? 0 : 10} item>
        <Typography className={classes.menuTitle}>{data?.name}</Typography>
        <Grid rowSpacing={2} container>
          {data?.products?.map((item) => (
            <Grid key={item.id} item justifyContent="space-between" container>
              <Grid item>
                <Typography fontWeight={600} fontSize={18}>
                  {item.name}
                </Typography>
                <Typography color="gray">
                  <small> {item.description}</small>
                </Typography>
              </Grid>
              <Grid item>
                <Typography fontWeight={600} fontSize={18}>
                  {currencyFormat(Number(item.price))}{' '}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
