import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../common/context/hooks';
import { globalRoutes } from '../../../common/utils/routes';
import { isOpenedOrderAndMenu } from './DiffRoute';
import { isUndefined } from 'swr/_internal';
import { Loading } from '../../../common/components/Loading/Loading';

export const GlobalRoute = () => {
  const location = useLocation();
  const store = useAppSelector((state) => state.general.brand?.store);
  const { isMenu, isOrderOpen } = store || {};

  if (isUndefined(isMenu) || isUndefined(isOrderOpen)) {
    return <Loading />;
  }

  const alternativeRoute = isOpenedOrderAndMenu({
    isMenu,
    isOrderOpen,
    pathname: location.pathname,
  });

  return <>{alternativeRoute ?? globalRoutes}</>;
};
