import { Box, CircularProgress, Grid, Typography } from '@mui/material';

interface LoadingProps {
  size?: number;
  gap?: number;
}

export const Loading = ({ size = 30, gap = 5 }: LoadingProps) => {
  return (
    <Grid justifyContent="center" alignItems="center" container>
      <Typography fontSize={size}>
        <Box sx={{ padding: gap }}>
          <CircularProgress />
        </Box>
      </Typography>
    </Grid>
  );
};
