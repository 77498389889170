import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {},
  categoryListContainer: {
    width: '100vw',
    transition: '300ms all ease-in-out',
    backgroundColor: '#fff',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
  },
  categoryListContainerClose: {
    left: '-120vw',
  },
  categoryListTitleContainer: {
    height: '7vh',
    backgroundColor: '#000',
    color: 'white',
    padding: theme.spacing(0, 2),
  },
  categoryList: {
    height: '90vh',
    overflow: 'auto',
  },
  category: {
    padding: theme.spacing(2, 3),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    '&:active': {
      backgroundColor: 'rgba(255,255,255,0.5)',
    },
  },
  // CONTENT //
  logo: {
    backgroundColor: '#000000',
    height: '60px',

    color: 'white',
  },
  menuTitle: {
    backgroundColor: '#000000',
    height: '50px',
    alignItems: 'center',
    color: '#FFFFFF',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: theme.spacing(0, 2),
  },
  productContainer: {
    height: 'calc(100vh - 110px)',
    overflow: 'hidden auto',
    padding: theme.spacing(1),
  },
  productItem: {
    padding: theme.spacing(1.2, 0),
  },
  image: {
    width: '100%',
    borderRadius: 4,
  },
}));
