import { Button, Grid, Typography } from '@mui/material';
import { MouseEvent } from 'react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from '../../../../common/context/hooks';
import { MenuItem } from '../../../../common/context/models';
import { useStyles } from './Theme2.styles';
import { currencyFormat } from '../../../../common/utils/helper';
import { Logo } from '../../../../common/components/Logo/Logo';
import { getCdnImageUrl } from '../../../../common/helpers/image';

export const Theme2 = ({ menu }: { menu: MenuItem[] }) => {
  const { classes } = useStyles();

  const brand = useAppSelector((state) => state.general.brand);

  const onGoHref = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const href = (event.target as any)?.attributes?.href?.nodeValue;
    const indexSharp = href?.indexOf('#');
    const id = href?.substring(indexSharp + 1, href.length);
    const wanted = document.getElementById(id);
    const position = wanted?.getBoundingClientRect();
    if (!position) return;
    window.scrollTo({
      top: position.top + window.scrollY - 130,
      left: position.left + window.scrollX,
      behavior: 'smooth',
    });
  };

  return (
    <Grid container>
      <Grid xs={12} className={classes.root} position="fixed" item container>
        <Grid
          xs={12}
          justifyContent="center"
          alignItems="center"
          className={classes.topbar}
          item
          container
        >
          {brand?.store?.logo ? (
            <Logo xs={12} justifyContent="center" />
          ) : (
            <Typography
              fontSize={20}
              fontWeight={600}
              color="white"
              variant="h4"
            >
              {brand?.store.title}
            </Typography>
          )}
        </Grid>
        <Grid xs={12} item container>
          <Swiper
            spaceBetween={10}
            className={classes.slider}
            modules={[Scrollbar]}
            breakpoints={{
              0: {
                slidesPerView: 2.1,
                slidesPerGroup: 2,
              },
              900: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
              1200: {
                slidesPerView: 4,
                slidesPerGroup: 1,
              },
            }}
            scrollbar
          >
            {menu.map((menuData, index) => (
              <SwiperSlide key={index} className={classes.slideItem}>
                <Button
                  href={`#${menuData.name}`}
                  onClick={onGoHref}
                  component="a"
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  fullWidth
                >
                  {menuData.name}
                </Button>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>
      </Grid>
      <Grid mt={12} pb={15} justifyContent="center" container>
        {menu?.map(
          (menuData) =>
            menuData.products.length > 0 && (
              <Grid
                id={menuData.name}
                key={menuData.id}
                mt={7}
                xs={12}
                lg={5}
                sx={(theme) => ({
                  p: { lg: 4, xs: theme.spacing(0, 2, 0, 2) },
                })}
                alignContent="start"
                item
                container
              >
                <Grid xs={12} item container>
                  <Typography variant="h5" fontSize={30} fontWeight="bold">
                    {menuData.name}
                  </Typography>
                </Grid>
                <Grid xs={12} item container>
                  {menuData.products?.map((menuItem) => (
                    <Grid
                      key={menuItem.id}
                      pt={2}
                      xs={12}
                      spacing={3}
                      item
                      container
                    >
                      <Grid flex={1} alignContent="start" item container>
                        <Grid xs={12} item container>
                          <Grid item xs={10}>
                            <Typography
                              className={classes.productName}
                              fontWeight="bold"
                            >
                              <span>{menuItem.name}</span>
                            </Typography>
                          </Grid>
                          <Grid xs={2} item>
                            <Typography fontWeight="bold" fontSize="1.15em">
                              {currencyFormat(Number(menuItem.price))}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="caption" color="secodary">
                            {menuItem.description}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid sx={{ width: 80 }} item container>
                        {menuItem.image && (
                          <img
                            src={getCdnImageUrl(menuItem.image)}
                            alt={menuItem.name}
                            className={classes.image}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ),
        )}
      </Grid>
    </Grid>
  );
};
