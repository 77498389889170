import { Grid, Typography } from '@mui/material';
import { ReactComponent as Icon404 } from '../../../assets/icons/404.svg';
import { Logo } from '../../../common/components/Logo/Logo';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { Link } from 'react-router-dom';

interface PageNotFound404Props {
  title: string;
  subtitle: string;
  code?: string;
  buttonText?: string;
  buttonLink?: string;
  hideButton?: boolean;
}

export const PageNotFound404 = ({
  title,
  subtitle,
  code = '404',
  buttonText,
  buttonLink = '/',
  hideButton,
}: PageNotFound404Props) => {
  return (
    <Grid
      alignItems="center"
      direction="column"
      style={{ height: '100vh', overflow: 'hidden', flexWrap: 'nowrap' }}
      container
    >
      <Logo justifyContent="center" style={{ fontSize: '3em' }} />
      <Grid justifyContent="center" pb={8} item container>
        <Grid xs={5} justifyContent="center" style={{ fontSize: '4em' }} item>
          <Icon404 />
        </Grid>
        <Grid
          xs={5}
          justifyContent="center"
          alignItems="center"
          direction="column"
          item
          container
        >
          <Typography fontSize={'8em'} fontWeight={600}>
            {code}
          </Typography>
          <Typography fontSize={30} fontWeight={600}>
            {title}
          </Typography>
          <Typography fontSize={20} fontWeight={600}>
            {subtitle}
          </Typography>
          <Grid pt={10}>
            {!hideButton && (
              <SahinButton
                variant="space"
                component={Link}
                to={buttonLink}
                strong
                soft
              >
                {buttonText || "ANASAYFA'YA DÖN"}
              </SahinButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
