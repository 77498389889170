import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    zIndex: 10,
  },
  topbar: {
    maxHeight: 60,
    padding: theme.spacing(1, 0),
    background: theme.palette.primary.main,
  },
  slider: {
    padding: '10px 50px 20px 20px!important',
    alignItems: 'center',
    background: '#fff',
    boxShadow: '0px 0px 20px 2px rgba(0, 0, 0, 0.2)',
  },
  slideItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'monospace',
    fontSize: '1em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  productName: {
    position: 'relative',
    '& > span': {
      background: '#fff',
      display: 'inline-block',
      paddingRight: 10,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 14,
      left: '5%',
      width: '90%',
      borderBottom: `1px dashed ${theme.palette.primary.contrastText}`,
      zIndex: -1,
    },
  },
  image: {
    width: '100%',
    borderRadius: 4,
    height: 'fit-content',
  },
}));
