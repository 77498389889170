import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()({
  header: {
    position: 'relative',
    width: '100%',
    height: 350,
    '&::after': {
      content: '""',
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.6)',
      position: 'absolute',
      zIndex: 1,
    },
  },
  imageBg: {
    position: 'absolute',
    width: '100%',
    height: 350,
    objectFit: 'cover',
  },
  title: {
    fontSize: '4em',
    fontWeight: 600,
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%)',
    zIndex: 5,
    color: 'white',
  },
  menuTitle: {
    fontSize: '2.2em',
    marginBottom: '.3em',
    marginTop: '.1em',
  },
  menuItem: {
    padding: '10px 20px',
    margin: 10,
    borderRadius: 10,
  },
  home: {
    width: '100%',
    background: '#7f954085',
    padding: 10,
    textAlign: 'center',
    fontWeight: 600,
    color: 'white',
    textDecoration: 'none',
    letterSpacing: '2px',
    display: 'block',
  },
});
