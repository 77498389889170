import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { default as moment } from 'moment';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../common/context/hooks';
import { initI18n } from '../common/i18n/I18n';
import { getTheme } from '../common/utils/theme';
import { BrandProvider } from './managers/BrandProvider/BrandProvider';

// CSS
import 'react-confirm-alert/src/react-confirm-alert.css';

// INIT
import 'moment/locale/tr';
import { GlobalRoute } from './pages/GlobalRoute/GlobalRoute';
moment.locale('tr');
initI18n();

export const App = () => {
  const themeMode = useAppSelector((state) => state.general.theme);
  const theme = useMemo(() => getTheme(themeMode), [themeMode]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const generalComponents = useMemo(
    () => (
      <>
        <BrandProvider />
      </>
    ),
    [],
  );

  return (
    <>
      {generalComponents}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalRoute />
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};
