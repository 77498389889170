import { Grid, Typography } from '@mui/material';
import { useStyles } from './Theme3.styles';

import ListIcon from '@mui/icons-material/List';
import { useState } from 'react';
import { MenuItem } from '../../../../common/context/models';
import { useAppSelector } from '../../../../common/context/hooks';
import { currencyFormat } from '../../../../common/utils/helper';
import { Logo } from '../../../../common/components/Logo/Logo';
import { getCdnImageUrl } from '../../../../common/helpers/image';
import { Close } from '@mui/icons-material';

export const Theme3 = ({ menu }: { menu: MenuItem[] }) => {
  const { classes, cx } = useStyles();

  const brand = useAppSelector((state) => state.general.brand);

  const [selectedCategory, setSelectedCategory] = useState<MenuItem>(menu[0]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Grid className={classes.root} container>
      <Grid
        className={cx({
          [classes.categoryListContainer]: true,
          [classes.categoryListContainerClose]: !drawerOpen,
        })}
        container
      >
        <Grid
          xs={12}
          className={classes.categoryListTitleContainer}
          justifyContent="space-between"
          alignItems="center"
          item
          container
        >
          <Typography variant="h5" fontSize={24} fontWeight={900}>
            KATEGORİLER
          </Typography>
          <Close fontSize="large" onClick={() => setDrawerOpen(false)} />
        </Grid>
        <Grid item xs={12} className={classes.categoryList}>
          {menu?.map(
            (menuData) =>
              menuData.products?.length > 0 && (
                <Grid
                  key={menuData.id}
                  onClick={() => {
                    setSelectedCategory(menuData);
                    setDrawerOpen(false);
                  }}
                  className={classes.category}
                  item
                >
                  <Typography variant="h6" fontSize={22} fontWeight={600}>
                    {menuData.name}
                  </Typography>
                </Grid>
              ),
          )}
        </Grid>
      </Grid>
      <Grid alignItems="start" item container>
        <Grid
          xs={12}
          className={classes.logo}
          alignItems="center"
          justifyContent="center"
          container
        >
          {!brand?.store?.logo ? (
            <Logo xs={12} justifyContent="center" />
          ) : (
            <Typography
              fontSize={30}
              fontWeight={600}
              color="white"
              variant="h4"
              textAlign="center"
            >
              {brand?.store.title}
            </Typography>
          )}
        </Grid>
        <Grid
          className={classes.menuTitle}
          xs={12}
          flexWrap="nowrap"
          item
          container
        >
          <ListIcon
            sx={{ cursor: 'pointer', mr: 2 }}
            fontSize="large"
            onClick={() => setDrawerOpen(true)}
          />
          <Typography
            variant="h3"
            fontSize={25}
            fontWeight="bold"
            textAlign="center"
          >
            {selectedCategory.name}
          </Typography>
        </Grid>
        <Grid
          className={classes.productContainer}
          alignContent="start"
          xs={12}
          item
          container
        >
          {selectedCategory.products?.map((menuItem) => (
            <Grid
              key={menuItem.id}
              className={classes.productItem}
              columnSpacing={2}
              xs={12}
              item
              container
            >
              <Grid xs={2} alignItems="center" item container>
                <img
                  src={getCdnImageUrl(menuItem.image)}
                  alt={menuItem.name}
                  className={classes.image}
                />
              </Grid>
              <Grid
                xs={7}
                justifyContent="space-evenly"
                flexDirection="column"
                item
                container
              >
                <Typography variant="h5" fontWeight="bold">
                  {menuItem.name || '-'}
                </Typography>
                <Typography variant="body2">
                  {menuItem.description || '-'}
                </Typography>
              </Grid>
              <Grid
                xs={3}
                justifyContent="end"
                alignItems="center"
                item
                container
              >
                <Typography variant="h4" fontSize={24} fontWeight="bold">
                  {currencyFormat(Number(menuItem.price || 0))}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
