import { Loading } from '../../../common/components/Loading/Loading';
import { MenuDTO } from '../../../common/context/models';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';
import { PageNotFound404 } from '../PageNotFound404/PageNotFound404';
import { Theme1 } from './Theme1/Theme1';
import { Theme2 } from './Theme2/Theme2';
import { Theme3 } from './Theme3/Theme3';
import { Theme4 } from './Theme4/Theme4';

export const MenuPage = () => {
  const { data, error } = useFetch<MenuDTO>(RequestPathNamespace.MENU);

  const Theme = [Theme1, Theme2, Theme3, Theme4][data?.theme ?? 0];

  if (data === undefined) return <Loading />;
  if (data === null || error)
    return (
      <PageNotFound404
        title="Menü yönetici tarafından kapatılmış"
        subtitle="Lütfen daha sonra tekrar deneyin..."
      />
    );
  return <Theme menu={data.menuData} />;
};
