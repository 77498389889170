import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useCallback } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SahinButton } from '../../../common/components/SahinButton/SahinButton';
import { SahinTextField } from '../../../common/components/TextField/SahinTextField';
import { SahinFetch } from '../../../common/helpers/Fetch';
import { yup } from '../../init/yupInit';
import { useStyles } from './ContactPage.styles';

const { REACT_APP_CONTACT_API_URL } = process.env;

const schema = yup
  .object({
    mail: yup.string().email().required(),
    phone: yup.string().required(),
    company: yup.string().required(),
    name: yup.string().required(),
    content: yup.string().required(),
    language: yup.string().required(),
    suggestion: yup.string().required(),
    isAdminPanel: yup.string().required(),
    projectType: yup.string().required(),
  })
  .required();

export const ContactPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      new SahinFetch()
        .post(REACT_APP_CONTACT_API_URL!, data)
        .then(() => {
          confirmAlert({
            title: 'Mesajınızı aldık',
            message: 'en yakın zamanda size dönüş yapacağız',
            buttons: [
              {
                label: 'Kapat',
              },
            ],
          });
        })
        .catch(() => {
          setError('password', {
            type: 'custom',
            message: t('errorMessages.notFoundUser'),
          });
        });
    },
    [setError, t],
  );

  return (
    <Grid xs={5} className={classes.root} component={Paper} item container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid spacing={2} container>
          <Grid justifyContent="center" container>
            <Typography fontWeight={600} fontSize={30}>
              İLETİŞİM
            </Typography>
          </Grid>
          <Box p={4} />

          <Grid justifyContent="center" container>
            <Typography fontWeight={600} fontSize={22}>
              {t('makeProject.personalInfo')}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <SahinTextField
              name="company"
              label={t('form.company')}
              placeholder={t('placeholder.company')}
              error={errors?.company?.message}
              inputProps={register('company')}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <SahinTextField
              name="name"
              label={t('form.fullName')}
              placeholder={t('placeholder.name')}
              error={errors?.name?.message}
              inputProps={register('name')}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <SahinTextField
              name="mail"
              label={t('form.mail')}
              placeholder={t('placeholder.mail')}
              error={errors?.mail?.message}
              inputProps={register('mail')}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <SahinTextField
              name="phone"
              label={t('form.phone')}
              placeholder={t('placeholder.phone')}
              error={errors?.phone?.message}
              inputProps={register('phone')}
              variant="filled"
              fullWidth
            />
          </Grid>
          <Box p={4} />
          <Grid justifyContent="center" container>
            <Typography fontWeight={600} fontSize={22}>
              {t('makeProject.aboutYourProject')}
            </Typography>
          </Grid>
          <Box p={1} />
          <Grid item xs={12}>
            <SahinTextField
              name="content"
              label={t('form.content')}
              placeholder={t('placeholder.content')}
              error={errors?.content?.message}
              inputProps={register('content')}
              variant="filled"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel sx={{ pb: 2 }}>
                {t('makeProject.isManagementPanel')}
              </FormLabel>
              <RadioGroup
                onChange={(event: any) => {
                  setValue('isAdminPanel', event.target.value);
                }}
                name="isAdminPanel"
              >
                <FormControlLabel
                  sx={{ pb: 2 }}
                  value="evet"
                  control={<Radio />}
                  label={t('label.yesHaveManagementPanel')}
                />
                <FormControlLabel
                  value="hayır"
                  control={<Radio />}
                  label={t('label.noNotHaveManagementPanel')}
                />
              </RadioGroup>
            </FormControl>
            <>{errors?.isAdminPanel?.message}</>
          </Grid>
          <Grid item xs={6}>
            <FormControl>
              <FormLabel sx={{ pb: 2 }}>
                {t('makeProject.isYourThink')}
              </FormLabel>
              <RadioGroup
                onChange={(event: any) => {
                  setValue('projectType', event.target.value);
                }}
                name="projectType"
              >
                {[
                  { value: 'kurumsal', label: t('label.corporateSite') },
                  { value: 'eticaret', label: t('label.eCommerceSite') },
                  { value: 'haber', label: t('label.newsPortalSite') },
                  { value: 'aday', label: t('label.electionCandidateSite') },
                  { value: 'diger', label: t('common.other') },
                ].map((item, index) => (
                  <FormControlLabel
                    key={index}
                    sx={{ pb: 0.5 }}
                    value={item.value}
                    control={<Radio />}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <>{errors?.projectType?.message}</>
          </Grid>
          <Grid item xs={6}>
            <SahinTextField
              name="language"
              label={t('form.language')}
              placeholder={t('placeholder.language')}
              error={errors?.language?.message}
              inputProps={register('language')}
              variant="filled"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <SahinTextField
              name="suggestion"
              label={t('form.suggestion')}
              placeholder={t('placeholder.suggestion')}
              error={errors?.suggestion?.message}
              inputProps={register('suggestion')}
              variant="filled"
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
          <Grid justifyContent="flex-end" item container>
            <SahinButton type="submit" disabled={!isValid}>
              {t('contact.submitButton')}
            </SahinButton>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
