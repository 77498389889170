import { createLink } from '../../../common/helpers/creeateRoute';
import { RoutePathNamespace } from '../../../common/models/RoutePathNamespace';
import { MenuPage } from '../MenuPage/MenuPage';
import { PageNotFound404 } from '../PageNotFound404/PageNotFound404';

interface Props {
  isOrderOpen?: boolean;
  isMenu?: boolean;
  pathname: string;
}

export const isOpenedOrderAndMenu = ({
  isOrderOpen,
  isMenu,
  pathname,
}: Props) => {
  const icon = ['🗿', '🪦', '🧿'][Math.ceil(Math.random() * 100) % 3];
  const alternativeRoute = [
    {
      isEqual:
        !isOrderOpen && isMenu && pathname === RoutePathNamespace.HOME_PAGE,
      component: <MenuPage />,
    },
    {
      isEqual:
        !isOrderOpen && !isMenu && pathname === RoutePathNamespace.HOME_PAGE,
      component: (
        <PageNotFound404
          title="Restorant'ımız Şuan Sipariş Almıyor"
          subtitle="Lütfen daha sonra tekrar deneyin..."
          code={icon}
          hideButton
        />
      ),
    },
    {
      isEqual:
        !isMenu &&
        pathname ===
          createLink(RoutePathNamespace, RoutePathNamespace.HOME_PAGE),
      component: (
        <PageNotFound404
          title="Menü yönetici tarafından kapatılmış"
          subtitle="Lütfen daha sonra tekrar deneyin..."
        />
      ),
    },
  ];

  for (const element of alternativeRoute) {
    if (element.isEqual) {
      return element.component;
    }
  }
  return undefined;
};
