import { Grid, IconButton, Typography } from '@mui/material';
import { useStyles } from './Theme4.styles';

import { ArrowBackIos } from '@mui/icons-material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState } from 'react';
import { useAppSelector } from '../../../../common/context/hooks';
import { MenuItem, MenuProduct } from '../../../../common/context/models';
import { getCdnImageUrl } from '../../../../common/helpers/image';
import { currencyFormat } from '../../../../common/utils/helper';
import { Logo } from '../../../../common/components/Logo/Logo';

export const Theme4 = ({ menu }: { menu: MenuItem[] }) => {
  const { classes } = useStyles();

  const brand = useAppSelector((state) => state.general.brand);

  const [selectedCategory, setSelectedCategory] = useState<MenuItem | null>(
    menu[0],
  );

  return (
    <Grid className={classes.root} container>
      <Grid xs={5} className={classes.bgleft} item>
        <Typography
          color="white"
          variant="h2"
          fontWeight="bold"
          className={classes.brandTitle}
        >
          {brand?.store?.logo ? (
            <Logo xs={12} justifyContent="center" />
          ) : (
            <Typography
              fontSize={20}
              fontWeight={600}
              color="white"
              variant="h4"
            >
              {brand?.store.title}
            </Typography>
          )}
        </Typography>
      </Grid>
      <Grid xs={7} item className={classes.bgRight}></Grid>
      <Grid className={classes.categoryTitle} container>
        <Typography color="white" variant="h2" fontWeight="bold">
          {selectedCategory?.name ?? 'Kategoriler'}
        </Typography>
        {selectedCategory && (
          <Grid item>
            <IconButton
              onClick={() => setSelectedCategory(null)}
              className={classes.arrow}
            >
              <ArrowBackIos fontSize="large" />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Grid className={classes.categoryListContainer} item container>
        {!selectedCategory &&
          menu.map(
            (menuData) =>
              menuData.products?.length > 0 && (
                <ProductItem
                  key={menuData.id}
                  product={{
                    id: menuData.id,
                    name: menuData.name,
                    description: `${menuData.products.length} Ürün`,
                    image: menuData.image,
                    price: 0,
                  }}
                  onClick={() => setSelectedCategory(menuData)}
                  isCategory
                />
              ),
          )}
        {selectedCategory?.products.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </Grid>
    </Grid>
  );
};

export const ProductItem = ({
  product,
  isCategory,
  onClick,
}: {
  isCategory?: boolean;
  product: MenuProduct;
  onClick?: () => void;
}) => {
  const { classes } = useStyles();

  return (
    <Grid className={classes.categoryContainer} onClick={onClick} container>
      {product.image && (
        <img
          src={getCdnImageUrl(product.image)}
          alt={product.name}
          className={classes.image}
        />
      )}
      <Grid xs={8} ml={product.image ? 3 : 0} item>
        <Typography variant="h5" fontFamily="fantasy" whiteSpace="nowrap">
          {product.name}
        </Typography>
        <Typography>{product.description}</Typography>
      </Grid>
      {isCategory ? (
        <Grid item>
          <IconButton className={classes.arrow}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Grid>
      ) : (
        <Grid xs={4} item>
          <Typography variant="h6" textAlign="end" fontWeight="bold">
            {currencyFormat(Number(product.price))}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
