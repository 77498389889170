import { useCallback, useEffect, useState } from 'react';

export const useResponsive = () => {
  const [outerWidth, setOuterWidth] = useState(window.outerWidth);

  const resizeHandler = useCallback(() => {
    setOuterWidth(window.outerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [resizeHandler]);

  return {
    isXs: { down: outerWidth < 481, up: outerWidth > 481 },
    isMd: { down: outerWidth < 641, up: outerWidth > 641 },
    isLg: { down: outerWidth < 961, up: outerWidth > 961 },
  };
};
