import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../models/Nullable';
import { Brand, ThemeMode } from './models';

export enum CacheNames {
  AUTH = 'SESSION',
  BASKET = 'BASKET',
}

// type SahinPayload<T> = PayloadAction<T | null | undefined>;

export interface GeneralState {
  theme: ThemeMode;
  brand: Nullable<Brand>;
}

const initialState: GeneralState = {
  theme: ThemeMode.LIGHT,
  brand: null,
};

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setTheme: (state: GeneralState, action: PayloadAction<ThemeMode>) => {
      state.theme = action.payload;
    },
    setBrand: (state: GeneralState, action: PayloadAction<Brand>) => {
      state.brand = action.payload;
    },
  },
});

export const generalReducer = generalSlice.reducer;
export const generalActions = generalSlice.actions;
