import { useEffect } from 'react';
import { useAppDispatch } from '../../../common/context/hooks';
import { Brand } from '../../../common/context/models';
import { generalActions } from '../../../common/context/slice';
import { useFetch } from '../../../common/hooks/useFetchSwr';
import { RequestPathNamespace } from '../../../common/models/RequestPathNamespace';

export const BrandProvider = () => {
  const dispatch = useAppDispatch();
  const { data: brand, error } = useFetch<Brand>(RequestPathNamespace.BRAND);

  useEffect(() => {
    if (brand) {
      setTimeout(() => {
        dispatch(generalActions.setBrand(brand));
      }, 2000);
    }
  });

  useEffect(() => {
    if (error?.status === 404) {
      setTimeout(() => {
        window.location.href = 'https://menukolay.com.tr';
      }, 4000);
    }
  }, [error]);

  return <></>;
};
