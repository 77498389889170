import { Navigate } from 'react-router-dom';
import { ContactPage } from '../../application/pages/ContactPage/ContactPage';
import { MenuPage } from '../../application/pages/MenuPage/MenuPage';
import { createRoute } from '../helpers/creeateRoute';
import { RoutePathNamespace } from '../models/RoutePathNamespace';

export const defaultRoutes = createRoute([]);

export const globalRoutes = createRoute([
  {
    path: RoutePathNamespace.HOME_PAGE,
    element: <MenuPage />,
  },
  {
    path: RoutePathNamespace.CONTACT_PAGE,
    element: <ContactPage />,
  },
  {
    path: RoutePathNamespace.ALL_ROUTES,
    element: <Navigate to="/" replace={true} />,
  },
]);
