import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
  },
  bgleft: {
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    padding: 20,
  },
  bgRight: {
    backgroundColor: 'rgba(229, 216, 222, .2)',
  },
  categoryListContainer: {
    overflow: 'auto',
    height: '100vh',
    position: 'absolute',
    padding: '15%',
    paddingBottom: '0',
    flexDirection: 'column',
  },
  categoryOutContainer: {
    padding: 30,
  },
  categoryContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(3, 2, 3, 3),
    margin: theme.spacing(4, 6),
    borderRadius: 20,
    boxShadow: '1px 0px 10px #888888',
    justifyContent: 'space-between',
    cursor: 'pointer',
    alignItems: 'center',
    flexWrap: 'nowrap',
    position: 'relative',
  },
  arrow: {
    height: 50,
    width: 50,
    backgroundColor: 'white',
    boxShadow: '1px 0px 10px #888888',
    color: theme.palette.primary.main,
    transform: 'translateX(80%)',
  },
  image: {
    width: 70,
    height: 70,
    borderRadius: '20%',
    transform: 'translateX(-60px)',
    position: 'absolute',
  },
  categoryTitle: {
    position: 'fixed',
    top: 20,
    left: '16%',
    textShadow: `2px 0 10px rgba(0,0,0,.6),
      0 2px 10px rgba(0,0,0,.6),
      -2px 0 10px rgba(0,0,0,.6),
      0 -2px 10px rgba(0,0,0,.6)`,
    zIndex: 99,
  },
  brandTitle: {
    position: 'fixed',
    textShadow: '2px 2px black',
    top: '500px',
    transform: 'rotate(-90deg) ',
    transformOrigin: '0% 0%',
  },
}));
